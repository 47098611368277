import React, { Component } from 'react';

export class NotFoundPage extends Component {
    render() {
        return (
            <div>
                <h2>Error 404</h2>
            </div>
        )
    }
}

export default NotFoundPage;
